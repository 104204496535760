import React from 'react';
import { useAuth } from '../../Core/Providers';
import { RouteMiddlewareProps } from '../../Core/Components/Routes';
import { Navigate } from 'react-router-dom';

function AuthMiddleware({ children }: RouteMiddlewareProps) {

    const user: any = useAuth().user();

    if (!user.id) {
        return <Navigate to={'/'} />;
    }

    const roleids = [
        process.env.REACT_APP_DTC,
        process.env.REACT_APP_TAR,
        process.env.REACT_APP_TAA,
        process.env.REACT_APP_NTC,
        process.env.REACT_APP_NTA,
        process.env.REACT_APP_DDE,
        process.env.REACT_APP_DDR,
        process.env.REACT_APP_DDA,
        process.env.REACT_APP_NDR,
    ];

    if (roleids.includes(String(user.role_id))) {
        return children;
    }

    return <Navigate to={'/'} />;
}

export default AuthMiddleware;