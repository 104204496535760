import { RouteProps } from '../../../Core/Components/Routes';
import { lazy } from 'react';
import AuthMiddleware from '../../../Components/Middleware/AuthMiddleware';

/* Route */
const routes: RouteProps[] = [
  {
    path: '/about',
    element: lazy(() => import('../Pages/About')),
    index: true
  },
  {
    path: '/tm',
    element: lazy(() => import('../Pages/TrainingManagement')),
    index: true,
    private: true,
    middleware: AuthMiddleware
  },
  {
    path: '/tm/tc',
    element: lazy(() => import('../Pages/TrainingManagement/TrainingCalender')),
    index: true,
    private: true,
    middleware: AuthMiddleware
  },
  {
    path: '/tm/tc/input-form/:id?',
    element: lazy(() => import('../Pages/TrainingManagement/TrainingCalender/InputForm')),
    index: true,
    private: true,
    middleware: AuthMiddleware
  },
  {
    path: '/tm/nc',
    element: lazy(() => import('../Pages/TrainingManagement/NominationCentre')),
    index: true,
    private: true,
    middleware: AuthMiddleware
  },
  {
    path: '/knowledge-repository',
    element: lazy(() => import('../Pages/KnowledgeRepository')),
    index: true
  },
  {
    path: '/knowledge-repository/input-form/:id?',
    element: lazy(() => import('../Pages/KnowledgeRepository/InputForm')),
    index: true,
    private: true,
    middleware: AuthMiddleware
  },
  {
    path: '/knowledge-repository/requests',
    element: lazy(() => import('../Pages/KnowledgeRepository/EditRequests')),
    index: true,
    private: true,
    middleware: AuthMiddleware
  },
  {
    path: '/modules',
    element: lazy(() => import('../Pages/TrainingManagement/ManageModule')),
    index: true,
    private: true,
    middleware: AuthMiddleware
  },
  {
    path: '/tr/:id?',
    element: lazy(() => import('../Pages/TrainingReports')),
    index: true,
    private: true,
    middleware: AuthMiddleware
  },
  {
    path: '/cs',
    element: lazy(() => import('../Pages/ClaimStatus')),
    index: true,
    private: true,
    middleware: AuthMiddleware
  },
];

export default routes;