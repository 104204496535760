import React, { useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import '../chart.scss';
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApexCharts from "apexcharts";
import jsPDF from "jspdf";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { Http } from "../../../../Core/Services/HttpService";

function ProgressDetailsMainChart({ progressData, chartLink, noDataMessage, ptype, headingTitle }: any) {

    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const [chart, setChart] = React.useState<any>(null);
    const chartRef = React.useRef<any>(null);
    const [chartImg, setChartImg] = React.useState<any>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const chartfor = new URLSearchParams(location.search).get('chartfor') || 'all';
    const locationModal = location.state && location.state.modal;
    const linkState = (locationModal && chartfor !== 'discom') ? { state: { modal: locationModal } } : {};
    const setLinks = () => {
        if (!chartLink) return;
        let xaxis = document.querySelector(`#chart-${idRef.current} .apexcharts-xaxis`);
        xaxis?.setAttribute('style', 'pointer-events: auto;');

        let barClick = document.querySelector(`#chart-${idRef.current} .apexcharts-series`);
        barClick?.setAttribute('style', 'cursor: pointer;');
        let labels = document.querySelectorAll(`#chart-${idRef.current} .apexcharts-xaxis-label`);
        if (progressData) {
            progressData.forEach((item: any, index: any) => {
                if (labels[index]) {
                    labels[index].setAttribute('style', 'cursor: pointer;');
                    labels[index].addEventListener('click', () => {
                        navigate(chartLink + '&id=' + item.id, linkState);
                    });
                }
            });
        }
    }
    const getStateName = () => {
        if ( progressData ) {
            return progressData.filter((item: any) => item.title != 'Total').map((item: any) => item.title);
        }
        return [];
    };
    const getProgressData = () => {
        if ( progressData ) {
            return progressData.filter((item: any) => item.title != 'Total').map((item: any) => item.progress);
        }
        return [];
    };
    useEffect(() => {
        const series = [{
            data: getProgressData(),
            name: '',
        }];
        const options: any = {
            annotations: {
                points: [{
                    seriesIndex: 0,
                    label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                            color: '#fff',
                            background: '#775DD0',
                        },
                    }
                }]
            },
            chart: {
                toolbar: {
                    show: false,
                },
                type: 'bar',
                events: {
                    dataPointSelection: (event: any, chartContext: any, { seriesIndex, dataPointIndex, config }: any) => {
                        if (!chartLink) return;
                        if (progressData && progressData[dataPointIndex]) {
                            let item = progressData[dataPointIndex];
                            navigate(chartLink + '&id=' + item.id, linkState);
                        }
                    },
                    mounted: () => {
                        const chartDiv: any = document.querySelector(`#chart-${idRef.current}`);
                        let settings = { childList: true },
                            observer = new MutationObserver(() => setLinks());

                        observer.observe(chartDiv, settings);
                        setLinks();
                    },
                    updated: async (chartContext: any, config: any) => {

                        const chartDiv: any = document.querySelector(`#chart-${idRef.current}`);
                        let settings = { childList: true },
                            observer = new MutationObserver(() => setLinks());

                        console.log('updated');
                        observer.observe(chartDiv, settings);
                        setLinks();
                        await chartContext.dataURI().then((res: any) => {
                            setChartImg(res);
                        });
                    },
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: '50%',
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2
            },
            grid: {
                borderColor: '#fff',
            },
            xaxis: {
                labels: {
                    rotate: -45,
                    style: {
                        fontSize: "10px",
                    }
                },
                categories: getStateName(),
                tickPlacement: 'on',
            },
            yaxis: {
                show: true,
                labels: {
                    formatter: (value: any) => {
                        return value + '%';
                    }
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: 0.25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [50, 0, 100]
                },
            }
        };

        if (progressData && !progressData.length) {
            options['noData'] = {
                text: noDataMessage,
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
            };
        }
        setChart({
            series: series,
            options: options
        });
    }, [progressData, noDataMessage, chartLink]);

    // const exportPDF = () => {
    //     const pdf = new jsPDF({
    //         orientation: 'landscape',
    //     });
    //     pdf.addImage(chartImg, 'PNG', 15, 40, 250, 160);
    //     pdf.save("progress-view-chart.pdf");
    // }

    const exportToPDF = () => {
        let content = document.getElementById(`chart-${idRef.current}`)?.cloneNode(true) as any;
        content = document.createDocumentFragment().appendChild(content);
        // Remove SVG TSPAN Tag
        const tspan = content.querySelectorAll(".apexcharts-xaxis-label > tspan");
        if (tspan && tspan.length > 0) {
            tspan.forEach((item: any) => item.remove());
        }
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const frmData = new FormData();
        frmData.append('content', encodeURIComponent(content.outerHTML));
        frmData.append('filename', 'progressdatachart');
        frmData.append('download', 'no');
        frmData.append('orientation', 'landscape');
        Http.post('/apiv1/contentExportPdf', frmData, config)
            .then((res) => {
                if (res._resultflag == "1") {
                    window.open(res.file, "_blank");
                }
            }).catch((e) => {

            });
    };

    return (
        <>
            {(chart != null) && (
                <>
                    <div className="d-flex justify-content-end mt-2">
                        <a title="Export to PDF" onClick={exportToPDF}><div className="pdfBox"><BsFileEarmarkPdf /></div></a>
                    </div>
                    <div id={`chart-${idRef.current}`}>
                        <div className="row d-none">
                            {ptype == 'cm' ? headingTitle + ' - Consumer Metering (%)(Installed/ Sanctioned)' : ''}
                            {ptype == 'dm' ? headingTitle + ' - DT Metering (%)(Installed/ Sanctioned)' : ''}
                            {ptype == 'fm' ? headingTitle + ' - Feeder Metering (%)(Installed/ Sanctioned)' : ''}
                            {ptype == 'bm' ? headingTitle + ' - Boundry Metering' : ''}
                            {(ptype.match(/_LT$/) || ptype == 'LT') ? headingTitle + ' - LT lines' : ''}
                            {(ptype.match(/_HT$/) || ptype == 'HT') ? headingTitle + ' - HT lines(%) (Installed/ Sanctioned)' : ''}
                            {(ptype.match(/_DTR$/) || ptype == 'DTR') ? headingTitle + ' - DTRs' : ''}
                            {(ptype.match(/_SUB$/) || ptype == 'SUB') ? headingTitle + ' - Substations' : ''}
                            <span> {new Intl.DateTimeFormat('en-US', { timeStyle: 'medium', dateStyle: 'medium', timeZone: 'Asia/Kolkata' }).format(new Date())}</span>
                        </div>
                        <ReactApexChart ref={chartRef} options={chart.options} series={chart.series} type="bar" height={600} />
                    </div>
                </>
            )}
        </>
    );
};

ProgressDetailsMainChart.defaultProps = {
    noDataMessage: 'Loading...'
};

export default ProgressDetailsMainChart;
